<template>
  <div>
    <!-- 头部 -->
    <div v-for="(item, index) in templateList.moduleList" :key="index">
      <Header :module="item" v-if="item.type == 201" />
    </div>

    <!-- 中间展示 -->
    <router-view style="min-height: calc(100vh - 300px)"></router-view>

    <!-- 底部 -->
    <div v-for="(item, index) in templateList.moduleList" :key="index">
      <Footer v-if="item.type == 207" />
    </div>
    <div v-if="templateList.moduleList.every((item) => item.type !== 207)" style="padding: 25px; text-align: center">
      <span v-html="webSetting.detail.beianCode"></span>
    </div>

    <!-- 联系客服 -->
    <div class="coustom-server" style="top: 600px" v-if="this.setting.sideCustomerStatus">
      <div class="el-tooltip server-content" @click="toCustomer()">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoBAMAAACREs5gAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAtUExURUdwTPX6//T6//T6//b7//f7//X6//X7//H4//T5//v9/+r1/ziZ/8rk/5LM/zkDzM8AAAAJdFJOUwCUV+pxNbYb2GqpHEUAAAGjSURBVCjPnZM7SwNBEMcnZ3JneeALYnOIImpjtPCRRlAErQQVhDRXKcbmRBDEJhK1SRUD4mQrX8Vdp6K4jH4AwcsXCH4C/RDOPRNjqvxh2d3fzfxvbmcPoANt9G/3rrUwrcfx1PeXpp2Tkc3hvLPTDNedAT9j0TEaUIlj0tcNOn4XrVLzeryscp42vDTqecUBB7xS8lzCqRcRlZc2Qcv7lZ2xWyE0uLW4iEAGqKEFz9p8SG8gVQno0SR0O3ZAbQt2g5JXdFit+kLbNmHM9L5gem4N5my0EZHxPXRVMpyPCApvOc62q1gGFdGAMbyCBMbyNzrTS+9xLEgGtAzdTdRSmul3OBpURSldIYTLMwRUliEhUDIRrsBX9pVMRRmSkrBG4t2V8gFUIXQ4xgtISUk/JN8+BD17LzFAKfLpZ4neal+fdRIm5GYz4QnvkaCfT5foyYLBhagbJWLVeTyClrWixlEkAxIPcecjyB3KzTRoZtmD3E2tFNvCELsEnVdf2txMba/Qhm69Wv/hPuktuRaklmmqJWziqegX8VeHJTrv6+TX+QVnR9FNMgbAPgAAAABJRU5ErkJggg=="
          alt="" draggable="false" />
        <p>联系客服</p>
      </div>

      <div class="server-content">
        <div>
          <el-backtop>
            <div class="pBacktop">
              <i class="el-icon-caret-top"></i>
            </div>
          </el-backtop>
        </div>

        <div>返回顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Footer from "@/pages/pc/common/footer";
import Header from "@/pages/template/pc/module/head/common.vue";

import { getWebTemplate } from "../api/index";

export default {
  name: "page",

  data() {
    return {
      templete: "",
      isRouterAlive: true,
      websiteName: "",
      description: "",
      keywords: "",
      templateList: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState({
      webSetting: (state) => state.index.webSetting,
      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },
  created() {
    // location.reload();

    //加载用户信息
    this.$store.dispatch("user/info");

    this.initData();
  },
  methods: {
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.templateList = response.data;
        }
      });
    },

    /**
     * 跳转到联系客服
     */
    toCustomer() {
      this.$router.push({ path: "customer" });
    },
  },
};
</script>
<style>
.coustom-server {
  width: 94px;
  overflow: hidden;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.051);
  border-radius: 20px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  right: 40px;
  transform: translateY(-70%);
  z-index: 99999;
}

.coustom-server .server-content:first-child {
  background: linear-gradient(135deg, #2db1ff, #1479ff);
}

.coustom-server .server-content {
  height: 104px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}

.coustom-server .server-content img {
  width: 40px;
  height: 40px;
}

.coustom-server .server-content:first-child p {
  color: #fff;
}

.coustom-server .server-content p {
  font-size: 12px;
  font-weight: 500;
  color: #41435d;
}

.coustom-server {
  width: 94px;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.051);
  border-radius: 20px;
  background-color: #fff;
  position: fixed;
  z-index: 99999;
}

.coustom-server .move-item {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.coustom-server .move-item:active,
.coustom-server .move-item:focus {
  color: #1479ff;
}

.coustom-server .server-content {
  height: 104px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}

.coustom-server .server-content img {
  width: 40px;
  height: 40px;
}

.coustom-server .server-content p {
  font-size: 12px;
  font-weight: 500;
  color: #41435d;
}

.coustom-server .server-content:first-child {
  background: linear-gradient(135deg, #2db1ff, #1479ff);
  border-radius: 20px 20px 0 0;
}

.coustom-server .server-content:first-child p {
  color: #fff;
}

.coustom-server .disabled {
  pointer-events: none;
}

.custom-code-box {
  width: 130px;
  height: 140px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.custom-code-box img {
  width: 100%;
  height: 130px;
}

.custom-code-box p {
  font-size: 15px;
  font-weight: 700;
}

/deep/.el-dialog {
  border-radius: 10px;
}

.el-backtop {
  position: static;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
}

.pBacktop {
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 40px;
  color: #1989fa;
}
</style>
